.terms_main_wrap{
    padding: 1rem;
    padding-bottom: 4rem;

    .terms_heading{
        font-size: 1.5rem;
        text-align: center;
        font-weight: 700;
    }

    .terms_subheading{
        font-size: 1rem;
        font-weight: 600;
        margin-top: 1.2rem;
    }
    .content{
        font-size: 0.75rem;
        font-weight: 400;
        text-align:left;
        margin-top: 0.125rem;
        letter-spacing: 0.05rem;
        text-align: justify;
    }
}