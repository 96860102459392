@mixin displayCode($para1){
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: #111;
    z-index: 1000;
    box-shadow: 0 -4px 10px #0000001A;

    ul {
        width:100%;
        height:3.25rem;
        display: flex;
        justify-content:center;
        margin: 0;
        padding: 0;

        .casinoTab{
            flex-basis:33.33%;
            height:100%;
            background-image: url('../../assets/images/tabBackground.png');
            background-repeat: no-repeat;
            background-size: cover;
            @include displayCode(center);
        }

        a {
            flex-basis:33.33%;
            color: #111;
            text-decoration: none;
            height:100%;

            .bBarFields{
                height:100%;
                flex-basis:33.33%;
                @include displayCode(center);
                flex-direction: column;
                gap:0rem;
                padding-top:0.3rem;

                li {
                    list-style: none;
                    font-size: 0.5rem;
                    
                }
            }
        }
    }
}

.active{
    height:100%;
    flex-basis:33.33%;
    @include displayCode(center);
    flex-direction: column;
    gap:0rem;
    padding-top:0.3rem;
    border-top:2px solid #000000;
}

.inactive{
    border-top: 0 !important;
}
