@mixin displayCode($para1){
    display:flex;
    justify-content:$para1;
    align-items:center;
}

@mixin responsive(){
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_main_wrap{
    width: 100%;
    height: 100vh;
    @include displayCode(none);
    justify-content: space-between !important;
    flex-direction: column;
    font-family: "Poppins";
    position: relative;

    .logn_sub{
        width:100%;
        @include displayCode(center);
        flex-direction: column;
        padding:0.875rem;

        .input_section{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 1.125rem;
            padding:0.875rem 0.5rem;
    
            .username{
                max-width: 100%;
                width:23.5rem;
                @include displayCode(none);
                background-color: #F5F6F8;
                height:3.25rem;
                border-radius:1.5625rem;
                gap:1rem;
                padding:0 3rem 0 1.5rem;
    
                .logInp{
                    max-width: 100%;
                    border: none;
                    outline: none;
                    background: #F5F6F8;
    
                    &:focus{
                        border: none;
                        outline: none;
                    }
                  }
            }
           
            .errormsg{
                width:100%;
                color: red;
                font-size: 0.8rem;
                display:flex;
                justify-content: flex-start;
                
            }
    
            .password{
                max-width: 100%;
                width:23.5rem;
                @include displayCode(none);
                background-color: #F5F6F8;
                height:3.25rem;
                border-radius:1.5625rem;
                padding:0 3rem 0 1.5rem;
                position: relative;
                margin-top:1.1875rem;
    
                .logInp{
                    @include displayCode(start);
                    max-width: 100%;
                    border: none;
                    outline: none;
                    background: #F5F6F8;
                    padding:0 0rem 0 1rem;
    
                    &:focus{
                        border: none;
                        outline: none;
                    }
                  }
    
                  .eyeIcon{
                    position: absolute;
                    right:1.375rem;
                  }
            }
            
        }

        .fp_cont{
            width: 100%;
            @include displayCode(space-between);
            padding:0 0.5rem;
    
            .fp_lf{
                @include displayCode(center);
                gap:0.5rem;
    
                .fpText1{
                    font-size:0.75rem;
                    font-weight:500;
                }
        
                .fp_checkBox{
                    width:1.25rem;
                    height:1.25rem;
                }
            }
    
            .fpText2{
                font-size:0.75rem;
                font-weight: 600;
            }
    
            
        }
    }

    

    

    .login{
        padding-top: 3.43rem;
        font-size: 1.375rem;
        font-weight: 600;
        color: var(--primary-color);
        text-align: center;
    }

    .btn_section{
        position: absolute;
        bottom: 2.1875rem;
        width:100%;
        @include displayCode(center);
        flex-direction: column;
        gap:0.25rem;
        padding: 0 1.375rem;

        .terms{
            font-size: 0.75rem;
            font-weight: 500;
            color: rgba(158, 158, 158, 1);
        }

        .lg_btn{
            @include displayCode(center);
            width: 100%;
            height:2.435rem;
            outline: none;
            border: none;
            border-radius:1.5625rem;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%),
                radial-gradient(50% 53.75% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
            background-color: rgba(29, 11, 60, 1);
            color: #fff;
            font-size: 0.9375rem;
            font-weight: 500;
    
        }

        .a_cont{
            width:100%;
            @include displayCode(center);
            align-items: flex-start;
    
            .aText{
                color: #888888;
                text-align: center;
                font-size:0.75rem;
                font-weight: 500;
        
                .signIn{
                    color:var(--primary-color);
                    font-size:0.75rem;
                    font-weight: 500;
                }
            }
        }
    }    

    

    
   
}

@media screen and (min-width:600px){
    .login_main_wrap{
        @include responsive()
    }
}
