.promotion_main_wrap{
    width: 100%;
    padding: 0.5rem;

    .promotion_banner_list{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .promotion_small_banner{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: 0.75rem;
        gap: 0.5rem;
        padding-bottom: 3.5rem;
    }
}


@media screen and (min-width:600px){
    .promotion_main_wrap{
       width: 400px !important;
    }   
   }