@mixin displayCode($para1){
    display: flex;
    justify-content: $para1;
    align-items: center;
}


.nav_cont{
    @include displayCode(space-between);
    width:100%;
    height:3rem;
    padding:0 0.65rem;
    box-shadow: 0px 4px 5.7px 0px #0000001A;
    color: #111;
    background-color:#fff;
    font-size:0.75rem;

    .bal{
        @include displayCode(center);
        align-items: end;
        flex-direction: column;

        .bal_icon{
            @include displayCode(center);
            gap: 0.155rem;
        }
    }
}
