@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}


.deposite_amt_main_wrap{
    // width: 100%;
    padding: 2rem 1rem;
    position: relative;
    height: calc(100vh - 5rem);
    @include displayCode(center);
    justify-content: start;
    flex-direction: column;

   .deposit_box_wrap{
    width: 100%;
    height: 230px;
    margin-top: 2rem;
    border-radius: 0.5rem;
    padding: 1rem 0.875rem;
    background-color: rgba(235, 239, 243, 1);

    .error_msg{
        font-size: 0.5rem;
        padding:0.125rem 0.5rem 0 0.75rem;
        color: red;
    }

    .deposite{
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
    }

    .amt_input{
        padding: 0.65rem;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        border-radius: 2rem;
        outline: none;
        border: 1px solid rgba(211, 215, 221, 1);
        margin-top: 0.5rem;
        text-align: center;
    }

    .amt_val{
        @include displayCode(space-between);

        .min{
            font-size: 0.5rem;
            font-weight: 500;
            color: rgba(171, 171, 171, 1);
            padding: 0.125rem 0.875rem;
        }
    }

    .amt_list_wrap{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 0.8rem;
        gap: 1rem;

        .selected{
            text-align: center;
            background-color: rgba(29, 11, 60, 1);
            padding: 0.5rem;
            border-radius: 2rem;
            font-size: 0.75rem;
            border: 1px dotted rgba(171, 171, 171, 1);
            color: #fff;
        }

        .amt_value{
            text-align: center;
            background-color: rgba(255, 255, 255, 1);
            padding: 0.5rem;
            border-radius: 2rem;
            font-size: 0.75rem;
            border: 1px dotted rgba(171, 171, 171, 1);
        }
    }
    
   }


   .pay_button_wrap{
    @include displayCode(center);
    flex-direction: column;
    position: absolute;
    bottom: 3rem;
    width: 100%;
    padding: 0 1rem;

    .btn_info{
        font-size: 0.5625rem;
        font-weight: 500;
        text-align: center;
    }
    .pay_btn{
        width: 100%;
        background-color: rgba(29, 11, 60, 1);
        color: #fff;
        padding: 0.5rem;
        border-radius: 1.5625rem;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0.5rem;
    }
   }
}

@media screen and (min-width:600px){
    .deposite_amt_main_wrap,.nav_GCont{
       width: 400px !important;
    }   
   }

