@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.avatar_list_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:2rem 1rem;
    row-gap: 0.5rem;
    column-gap: 0.2rem;

    .selected{
        width: 66px;
        height: 66px;
        border: 2px solid rgba(29, 11, 60, 1);
        border-radius: 50%;
    }
}

.btn_selec_wrap{
    @include displayCode(center);

    .selectavatarbtn{
        width: 60%;
        padding: 0.75rem;
        background-color: rgba(31, 11, 67, 1);
        color: #fff;
        border-radius: 1.5625rem;
    }
}