@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

:root{
  --bg-primary:yellow;
  --primary-color:#000000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.carousel-container{
  gap:1rem;
}


/*carsolues*/
.react-multi-carousel-dot--active button{
  background-color: #fff !important;
}

.react-multi-carousel-dot button{
  width: 8px !important;
  height: 8px !important;
}

@media screen and (min-width:600px){
  .layout_main_wrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .games_game_cont__B8D6q .games_nav_GCont__eQdGg{
    width: 25rem !important;
  }

  nav{
    width: 25rem !important;
  }  
}

.react-multiple-carousel__arrow{
  display: none !important;
}