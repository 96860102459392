.splashScr{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}

@media screen and (min-width:600px){
    .splashScr{
       width: 400px !important;
    }   
   }