*{
    padding:0;
    margin:0;
    box-sizing: border-box;
  }
  
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  