@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

@mixin responsive() {
    width: 400px;
    display: flex;
    justify-content: center;
}


.profile_main_wrap {
    width: 100%;
    height: 100vh;
    background-color: rgba(29, 11, 60, 1);
    position: relative;
    background-image: url('../../components/ui/images/baclimg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .profile_content_wrap {
        width: 100%;
        height: calc(100% - 8rem);
        background-color: #fff;
        -webkit-box-shadow: -1px -5px 8px -6px rgba(0,0,0,0.66);
        -moz-box-shadow: -1px -5px 8px -6px rgba(0,0,0,0.66);
        box-shadow: -1px -5px 8px -6px rgba(0,0,0,0.66);
        border-top-left-radius: 2.5rem;
        border-top-right-radius: 2.5rem;
        position: absolute;
        top: 8rem;

        .user_info {
            @include displayCode(center);
            flex-direction: column;
            margin-top: -2.8rem;
            gap: 0.25rem;
            

            .user_profile_image{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                position: relative;
            }

            .edit_profil_icon{
                position: absolute;
                right:-1.4rem;
                bottom: 0.2rem;
            }


            .username {
                font-size: 1.4rem;
                font-weight: 400;
                margin-top: 0.125rem;
            }

            .border_bottm {
                width: 7.75rem;
                height: 0.1rem;
                background: linear-gradient(270deg, rgba(105, 84, 123, 0) 0%, rgba(105, 84, 123, 0.5) 50.59%, rgba(105, 84, 123, 0) 99.12%);
            }

            .userId_info {
               @include displayCode(center);
                gap: 0.5rem;
                margin-top: 0.4rem;

                .userId_info {
                    font-weight: 400;
                    font-size: 0.9375rem;
                }
            }
        }

        .user_account_sec {
            display: flex;
            padding: 3rem 2rem 1rem 2rem;
            justify-content: space-between;

            .border_bottm {
                width: 7rem;
                height: 0.1rem;
                background: linear-gradient(270deg, rgba(105, 84, 123, 0) 0%, rgba(105, 84, 123, 0.5) 50.59%, rgba(105, 84, 123, 0) 99.12%);
                margin-top: 0.5rem;
            }

            .icon {
                @include displayCode(none);
                gap: 1rem;
                cursor: pointer;
            }

            .bal {
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.5625rem;
            }

            .bal_amt {
                font-size: 1.5rem;
                font-weight: 400;
                font-family: 'Aceh Soft';
                line-height: 2.375rem;
                margin-top: 0.5rem;
            }

            .withdraw_section {
                .recharge {
                    font-size: 0.75rem;
                    font-weight: 500;
                }

                .withdraw_icon {
                    @include displayCode(none);
                    gap: 1rem;
                    margin-top: 0.5rem;
                    cursor: pointer;
                }
            }
        }


        .user_menu_list {
            padding: 1rem 1.5rem 3.5rem 0;

            .menu_list {
                @include displayCode(space-between);

                .menu_icon_name {
                    width: 100%;

                    .list_icon {
                        @include displayCode(none);
                        gap: 1rem;
                        padding: 1rem;
                        cursor: pointer;

                        .list_name {
                            font-size: 0.75rem;
                            font-weight: 500;
                        }

                    }

                    .border_bottm {
                        width: 100%;
                        height: 0.1rem;
                        background: linear-gradient(270deg, rgba(105, 84, 123, 0) 0%, rgba(105, 84, 123, 0.5) 50.59%, rgba(105, 84, 123, 0) 99.12%);
                    }
                }


            }


        }
    }


}


@media screen and (min-width:600px) {
    .user_profile_main_wrap {
        @include responsive();
        flex-direction: column;
    }

    .profile_main_wrap {
        @include responsive();
        flex-direction: column;
    }
}