@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.user_bal_main_wrap{
   @include displayCode(space-between);
   width: 100%;
   
    .user_section{
        @include displayCode(center);
        gap: 0.5rem;
        font-size: 0.875rem;

        .user_id{
            @include displayCode(center);
            gap: 0.5rem;
            margin-top: 0.5rem;

            .id{
                font-size: 0.8rem;
            }
        }
    }

    .user_balance_sec{
        .user_bal_amt{
            font-size: 1rem;
            font-weight: 400;
        }
        .avl_bal{
            font-size: 0.75rem;
            font-weight: 500;
        }
    }
}