@mixin displayCode($para1){
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.game_cont{
    @include displayCode(center);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    background:#f3f1f1;

    .nav_GCont{
        position: fixed;
        top: 0;
        width:100%;
        z-index: 100;
        box-shadow: 0px 4px 5.7px 0px #0000001A inset;
    }
    
    .gameList_cont{
        width:100%;
        column-count: 3;
        column-gap:0.5rem;
        padding:0.6rem 0.5rem 4rem 0.5rem;
        
        .gameImg{
            @include displayCode(center);
            width:100%;
            padding:0;
            margin:0;
            margin-bottom:0.5rem;
            break-inside: avoid;

            .gImg{
                border-radius:0.5rem;
                // width:7rem;
                height:10.65rem;
            }
        }
    }
}

@media (max-width:300px) {
    .carousel_cont{
        padding:0.5rem 0.5rem 2rem 0.5rem !important;
    }
}

@media screen and (min-width:600px){
    .game_cont{
        width: 400px;
    }
}

