.carousel_cont{
    padding:0.5rem 0.5rem 0rem 0.5rem;
    margin-top:3rem;
    width:100%;
    border-radius:0.3rem;
}

@media (max-width:300px) {
    .carousel_cont{
        padding:0.5rem 0.5rem 2rem 0.5rem !important;
    }
}