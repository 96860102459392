@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.deposite_box_main_wrap {
    @include displayCode(center);
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.65rem;
    padding: 4rem 0 4rem 0;

    .nav_GCont{
        position: fixed;
        top: 0;
        width:100%;
        z-index: 100;
        box-shadow: 0px 4px 5.7px 0px #0000001A inset;
    }

    .noresult_wrap{
        @include displayCode(center);
        flex-direction: column;
        gap: 1.5rem;

        .noresult{
            font-size: 0.875rem;
            font-weight: 500;
            color: rgba(158, 158, 158, 1);
        }
    }

    .deposite_box{
        width: 95%;
        height: 100px;
        background-color: rgba(235, 239, 243, 1);
        padding: 0.5rem 0.75rem;
        @include displayCode(space-between);
        border-radius: 0.5rem;
        box-shadow: 0px 2px 3.7px 0px rgba(0, 0, 0, 0.15);

    
        .deposite_left_side{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            font-size: 0.625rem;
            font-weight: 400;
            .name_wrap{
                @include displayCode(none);
                gap: 0.125rem;
                .payt_name{
                    font-size: 0.75rem;
                    font-weight: 500;
                }
            }
            
           }

           .deposite_right_side{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            font-size: 0.625rem;
            font-weight: 400;


            .amt{
                font-size: 0.75rem;
                font-weight: 500;
            }

            .transId{
                color:rgba(86, 108, 148, 1) ;
                font-weight: 600;
            }

            .failed{
                color: rgba(151, 62, 39, 1);
                font-weight: 600;
            }
            .confirm{
            color: rgba(52, 151, 39, 1);
            font-weight: 600;
           }
           .declined{
            color: rgba(178, 173, 26, 1);
            font-weight: 600;
           }
        }
    }
}

@media screen and (min-width:600px) {
    .deposite_box_main_wrap,.deposite_box,.nav_GCont {
        width: 400px !important;
    }
}